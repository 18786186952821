* {
    padding: 0;
    margin: 0;
}

.App{
    background-color: white;
}
@media screen and (max-width:768px) and (min-width:300px) {
    .divul {
        margin-left: 7px;
    }
}
